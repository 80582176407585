
export default {
  notfound: "/assets/images/404.svg",
  logo: "/vercel.svg",
  testicon: "/assets/icons/2082.png",
  testicon1: "/assets/icons/icon-192x192.png",
  testicon2: "/assets/icons/icon-256x256.png",
  testicon3: "/assets/icons/icon-384x384.png",
  testicon4: "/assets/icons/icon-512x512.png",
  image: "/assets/icons/auricular-phone_318-1028.webp",
  plane: "/assets/images/plane.webp",
  // number_background: "/assets/icons/number_back.svg",
  bulb_img: "/assets/images/buld_img.svg",
  LinkIcon: '/assets/images/i-link.svg',
  AddTokenIcon: '/assets/images/i-token.svg',
  partner1: "/assets/images/partner1.svg",
  partner2: "/assets/images/partner2.png",
  partner3: "/assets/images/partner3.svg",
  partner4: "/assets/images/partner4.png",
  partner5: "/assets/images/partner5.svg",
  partner6: "/assets/images/partner6.svg",
  partner7: "/assets/images/partner7.svg",
  partner8: "/assets/images/partner8.svg",
  sup1: "/assets/images/sup1.svg",
  sup2: "/assets/images/sup2.svg",
  sup3: "/assets/images/sup3.svg",
  sup4: "/assets/images/supp4.svg",
  sup5: "/assets/images/sup5.svg",
  sup6: "/assets/images/sup6.svg",
  headerlogo: "/assets/images/headerlogo.svg",
  headericon1: "/assets/images/headericon1.svg",
  twitter: "/assets/images/twitter.svg",
  shareIcon: "/assets/images/shareIcon.svg",
  fbIcon: "/assets/images/fb_icon.svg",
  instaIcon: "/assets/images/insta_icon.svg",
  Substract: "/assets/images/Subtract.svg",
  backGlow: "/assets/images/backGlow.png",
  sliderLOGO: "/assets/images/sliderLOGO.png",
  anglelogo: "/assets/images/anglelogo.svg",
  boat: "/assets/images/boat.svg",
  globe: "/assets/images/globe.svg",
  networkicon1: "/assets/images/networkicon1.svg",
  networkicon2: "/assets/images/networkicon2.svg",
  networkicon3: "/assets/images/networkicon3.svg",
  networkicon4: "/assets/images/networkicon4.svg",
  upArr: "/assets/images/upArr.svg",
  downArr: "/assets/images/downArr.svg",
  searchclaim: "/assets/images/searchclaim.svg",
  sliderLeft: "/assets/images/sliderLeft.svg",
  sliderRight: "/assets/images/sliderRight.svg",
  ftr_logo: "/assets/images/footer_logo.png",
  rocket_img: "/assets/images/rocketblue.webp",
  apply_lgo: "/assets/images/pulse_mdl_logo.svg",
  pink_box: "/assets/images/box.webp",
  user: "/assets/images/user.svg",
  launchicon1: "/assets/images/launch_icon1.svg",
  launchicon2: "/assets/images/launch_icon2.svg",
  launchicon3: "/assets/images/launch_icon3.svg",
  launchicon4: "/assets/images/launch_icon4.svg",
  launchicon5: "/assets/images/launch_icon5.svg",
  launchicon6: "/assets/images/launch_icon6.svg",
  plan_shadow: "/assets/images/plan-shadow.png",
  pulseicon: "/assets/images/pulse_iconblue.png",
  launchlisticon: "/assets/images/launch_list_icon.png",
  clog: "/assets/images/clog22.webp",
  accordion_arrw: "/assets/images/big_dwn_arrw.svg",
  glassback: "/assets/images/glassback.png",
  whitebrush: "/assets/images/whitebrush.svg",
  drag: "/assets/images/drag.svg",
  progressCheeck: "/assets/images/progressCheck.svg",
  bulb_img2: "/assets/images/bulb_img01.svg",
  stairImg: "/assets/images/bar-chart.png",
  Isolation: "/assets/images/Isolation.svg",
  staklogo1: "/assets/images/staklogo1.svg",
  staklogo2: "/assets/images/staklogo2.svg",
  cir: "/assets/images/cir.webp",
  pointerimg: "/assets/images/progressIcon.svg",
  kyc: "/assets/images/KYC.svg",
  planet: "/assets/images/planet.png",
  tiny_logo: "/assets/images/small_logo.svg",
  up_down_arrw: "/assets/images/up_down_arrw.svg",
  growth: "/assets/images/growth.svg",
  port1: "/assets/images/port1.png",
  port2: "/assets/images/port2.png",
  port3: "/assets/images/port3.png",
  port4: "/assets/images/port4.png",
  Star: "/assets/images/Star.svg",
  block1: "/assets/images/block1.svg",
  slidertoken: "/assets/images/slidertoken.png",
  sliderlogo: "/assets/images/sliderlogo.png",
  blueangle: "/assets/images/blueangle.svg",
  plainVideo: "/assets/images/plainVideo.mp4",
  blueBulb: "/assets/images/blb.webp",
  govtIcon: "/assets/images/govtIcon.svg",
  penaltyIcon: "/assets/images/penaltyIcon.svg",
  strict: "/assets/images/strict.svg",
  refund: "/assets/images/refund.svg",
  boxBlue: "/assets/images/box_blue.webp",
  bulb2: "/assets/images/bulb2.webp",
  ftr_icon1: "/assets/images/ftr_icon1.png",
  ftr_icon2: "/assets/images/ftr_icon2.png",
  msg_icon: "/assets/images/msg_icon.svg",
  webImg: "/assets/images/web.png",
  si1: "/assets/images/si1.png",
  si2: "/assets/images/si2.png",
  si3: "/assets/images/si3.png",
  si4: "/assets/images/si4.png",
  si5: "/assets/images/si5.png",
  si6: "/assets/images/si6.png",
  si7: "/assets/images/si7.png",
  copy_icon: "/assets/images/copy_icon.svg",
  sales_banner: "/assets/images/sales_ban.png",
  sales_avatar: "/assets/images/ido.png",
  sales_avatar2: "/assets/images/ido2.png",
  sales_icon1: "/assets/images/t1.svg",
  sales_icon2: "/assets/images/t2.svg",
  sales_icon3: "/assets/images/t3.svg",
  sales_icon4: "/assets/images/t4.svg",
  pulselogogray: "/assets/images/pulselogogray.svg",
  fadePlain: "/assets/images/fadePlain.png",
  ques_icon: "/assets/images/ques_icon.svg",
  plf_icon: "/assets/images/plf_icon.svg",
  meta_icon: "/assets/images/meta_icon.svg",
  sky_cat: "/assets/images/sky_cat.svg",
  gradient_border: "/assets/images/gradient_border.svg",
  iconEdit: "/assets/images/icon-edit.svg",
  iconStar: "/assets/images/icon-star.svg",
  question_drop: "/assets/images/question_img.svg",
  asest_box: "/assets/images/assest_box.svg",
  wht_search_icon: "/assets/images/wht_search_icon.svg",
  cross_icon: "/assets/images/cross_icon.svg",
  pulse_icon_new: "/assets/images/pulse_icon_new.svg",
  user_icon_img: "/assets/images/user_icon_img.svg",
  open_chat_icon: "/assets/images/open_chat_icon.svg",
  send_icon: "/assets/images/send_icon.svg",
  wallet_icon: "/assets/images/wale.svg",
  dash_icon: "/assets/images/m1.svg",
  calender_icon: "/assets/images/m2.svg",
  bag_icon: "/assets/images/m3.svg",
  chat_icon: "/assets/images/m4.svg",
  pulse_chat_logo: "/assets/images/pulse_chat_logo.svg",
  prevArrw: "/assets/images/prevArrw.svg",
  subLogo: "/assets/images/sublogo.svg",
  iconTrash: "/assets/images/icon-trash.svg",
  calender_back:"/assets/images/icon-calender_back.png",
  claim_icon: "/assets/images/claim_icon.svg",
  mobile_btm_back:"/assets/images/mobile_btm_back.png"
};
