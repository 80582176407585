import assest from "@/json/assest";
import CustomButton from "@/ui/Buttons/CustomButton";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import React, { useState } from "react";

interface mobileProps {
  stepZero: any;
  stepNext: any;
  handleOpenChatRooms: Function;
}

const ChatWelcomeWrapper = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    117.87% 70.95% at 50.13% 58.91%,
    #291c95 0%,
    #080520 100%
  );
  backdrop-filter: blur(4px);
  overflow-y: auto;
  h3 {
    font-weight: 800;
    font-size: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--white);
    max-width: 290px;
    margin: 25px 0 15px;
  }
  > p {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--white);
    max-width: 255px;
    margin-bottom: 40px;
  }
  button{
    min-width: 233px;
    p{
        span{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 15px;
        }
    }
  }
  .cross_btn{
    position: absolute;
    right: 20px;
    top: 20px;
    min-width: auto;
  }
`;

export default function ChatWelcome(props: mobileProps) {

  const openChatRooms = () => {
    const opened = props.handleOpenChatRooms();
    if (opened) {
      props.stepNext();
    }
  }

  return (
    <>
      <ChatWelcomeWrapper>
        <Image src={assest.pulse_chat_logo} alt="icon" width={70} height={32} />
        <Typography variant="h3">Welcome To PulseChat</Typography>
        <Typography variant="body1">
          Join the latest drops and what everyone's talking about! 
        </Typography>
        <CustomButton type="button" onClick={openChatRooms}>
          <Typography variant="body1">
            Join Chat
            <span>
              <Image
                src={assest.open_chat_icon}
                alt="icon"
                width={20}
                height={20}
              />
            </span>
          </Typography>
        </CustomButton>
        <Button className="cross_btn" onClick={props.stepZero}>
          <Image src={assest.cross_icon} alt="icon" width={20} height={20} />
        </Button>
      </ChatWelcomeWrapper>
    </>
  );
}
