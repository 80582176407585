import Wrapper from "@/layout/wrapper/Wrapper";
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Link from "next/link";
import React, { useCallback, useMemo, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import AddIcon from "@mui/icons-material/Add";
import Image from "next/image";
import assest from "@/json/assest";
import CustomCheckBox from "@/ui/Checkbox/CustomCheckBox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { orderBy } from "lodash";
import CustomButton from "@/ui/Buttons/CustomButton";

const ClaimList = styled(Box)`
  padding-top: 48px;
  @media (max-width: 1199px) {
    padding-top: 30px;
  }
  .MuiTypography-caption {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: var(--white);
    line-height: 1.3;

    a {
      color: var(--activeColor);
    }
  }
  .claim_list_upper {
    text-align: center;
    h2 {
      font-weight: 800;
      font-size: 28px;
      letter-spacing: 0.01em;
      color: var(--white);
      margin: 10px 0;
    }
  }
`;

const ListFilterSec = styled(Stack)`
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StackLeft = styled(Box)`
  ul {
    display: flex;
    justify-content: center;
    li {
      padding: 0;
      margin-right: 13px;
      width: auto;
      &:last-child {
        margin-right: 0;
      }
      button {
        width: 50px;
        height: 50px;
        background: url(/assets/images/gradient_border.svg) no-repeat center;
        background-size: 100% 100%;
        border-radius: 5px;
        min-width: auto;
        border: 1px solid rgba(255, 56, 235, 0.16);
        position: relative;
        z-index: 11;
        &:hover {
          background: radial-gradient(
              34.38% 238.67% at 49.5% 49.7%,
              rgba(55, 204, 218, 0.98) 0%,
              #028895 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        }
        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }
`;

const ClaimListItem = styled(Box)`
  margin-top: 32px;
  position: relative;
  z-index: 11;
  button {
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .enableClass {
    background: radial-gradient(
        34.38% 238.67% at 49.5% 49.7%,
        rgba(55, 204, 218, 0.98) 0%,
        #028895 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 1px solid #5cf1ff;
    width: 100%;
    text-align: initial;
    min-width: auto;
    .MuiTypography-body1 {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: var(--white);
    }
    .MuiTypography-body2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      letter-spacing: 0.01em;
      color: var(--white);
    }
  }

  .disableClass {
    background: rgba(255, 255, 255, 0.12) !important;
    border: 1px solid rgba(255, 255, 255, 0.16);
    width: 100%;
    text-align: initial;
    min-width: auto;
    .MuiTypography-body2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      letter-spacing: 0.01em;
      color: var(--white);
      span {
        color: var(--activeColor);
      }
    }

    .MuiTypography-body1 {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .claimedend {
    background: #0f0f0f;
    width: 100%;
    text-align: initial;
    min-width: auto;

    .MuiTypography-body1 {
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

const ListCradPaper = styled(Paper)`
  padding: 0;
  background: rgba(62, 64, 89, 0.3);
  border: 1px solid #242439;
  border-radius: 5px;
`;

const CardStack = styled(Box)`
  .MuiGrid-container {
    align-items: center;
  }

  .tokenTitleSec {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 10px;
    border-bottom: 1px solid #242439;
  }

  .MuiTypography-h3 {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: var(--white);
    margin-bottom: 5px;
    @media (max-width: 1600px) {
      font-size: 28px;
    }
    @media (max-width: 1366px) {
      font-size: 20px;
    }
  }

  .MuiTypography-caption {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    @media (max-width: 599px) {
      font-size: 12px;
    }
  }

  .addToken {
    text-align: center;
    color: var(--activeColor);
    display: block;
    @media (max-width: 552px) {
      padding: 0;
    }
    &:hover {
      background: transparent;
      p {
        color: var(--activeColor);
      }
    }

    .MuiTypography-body2 {
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: var(--white);
      line-height: 1.1;
      @media (max-width: 599px) {
        font-weight: 700;
        font-size: 10px;
        letter-spacing: 0.01em;
        color: rgba(255, 255, 255, 0.36);
      }
    }

    .iconGroup {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 8px;
      font-weight: 700;
      @media (max-width: 599px) {
        justify-content: flex-end;
      }
    }
  }
`;

const ClaimBox = styled(Stack)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #242439;
  .claimedCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
    &.white_text_wrap {
      margin-right: 0;
      .MuiTypography-h4 {
        color: var(--white);
      }
    }
    .MuiTypography-h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.1;
      letter-spacing: 0.01em;
      color: var(--activeColor);
    }

    .MuiTypography-subtitle1 {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: var(--white);
      line-height: 1.1;
    }

    .MuiTypography-subtitle2 {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: var(--white);
      line-height: 1.1;
      span {
        font-size: 10px;
        display: block;
        color: rgba(255, 255, 255, 0.36);
      }
    }
  }
`;

const TokenListClailm = styled(Box)`
  padding: 0 10px 10px 10px;
  .MuiGrid-container {
    align-items: center;
  }

  p{
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.01em;
    color: var(--white);
    span{
      font-weight: 700;
    }
    strong{
      color: rgba(255, 255, 255, 0.36);
      display: block;
    }
  }
  button{
    min-width: 100%;
  }


`;

const ClaimWrapper = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  background: rgba(18, 13, 57, 0.8);
  background-blend-mode: multiply;
  backdrop-filter: blur(4px);
  padding: 125px 15px 15px;
  overflow-y: auto;
  z-index: 99;
`;
type OrderBy = "asc" | "desc";
const array = [
  {
    title: "Name Of Token Claim",
    subtitle: "12th Round or Other Subtitle",
    count1: "1.235.000",
    claim: "Claimed",
    count2: "1.498.765.000",
    totalClaim: "1.500.000.000",
    isDisable: true,
    claimTimeText: "Claim ends in |",
    HoureText: "3 hrs 58 mins",
    DateText: "Started on 21 Feb 2023",
  },

  {
    title: "Name Of Token Claim",
    subtitle: "12th Round or Other Subtitle",
    count1: "0",
    claim: "Claim Now",
    count2: "2,345,678,901",
    totalClaim: "3,000,000,000",
    isDisable: false,
    claimTimeText: "Claim starts in |",
    HoureText: "2 hrs 40 mins",
    DateText: "Starts on 29 Mar 2023",
  },
  {
    title: "Name Of Token Claim",
    subtitle: "12th Round or Other Subtitle",
    count1: "0",
    claim: "Claim Now",
    count2: "1,234,567,890",
    totalClaim: "2,000,000,000",
    isDisable: false,
    claimTimeText: "Claim starts in |",
    HoureText: "4 hrs 30 mins",
    DateText: "Starts on 30 Mar 2023",
  },
];

const ClaimMobile = () => {


  return (
    <>
      <ClaimWrapper>
        <ClaimList>
          <Box className="claim_list_upper">
            <Image src={assest.claim_icon} alt="icon" width={39} height={50} />
            <Typography variant="h2">Token Claims</Typography>
            <Typography variant="caption">
              Select Network / <Link href="/">Select All</Link>
            </Typography>
          </Box>

          <ListFilterSec direction="row">
            <StackLeft>
              <List>
                <ListItem>
                  <Button>
                    <Image
                      src={assest.anglelogo}
                      alt="img"
                      width={16}
                      height={26}
                    />
                  </Button>
                </ListItem>
                <ListItem>
                  <Button>
                    <Image
                      src={assest.networkicon1}
                      alt="img"
                      width={16}
                      height={26}
                    />
                  </Button>
                </ListItem>
                <ListItem>
                  <Button>
                    <Image
                      src={assest.networkicon2}
                      alt="img"
                      width={16}
                      height={26}
                    />
                  </Button>
                </ListItem>
                <ListItem>
                  <Button>
                    <Image
                      src={assest.networkicon3}
                      alt="img"
                      width={16}
                      height={26}
                    />
                  </Button>
                </ListItem>
                <ListItem>
                  <Button>
                    <Image
                      src={assest.networkicon4}
                      alt="img"
                      width={16}
                      height={26}
                    />
                  </Button>
                </ListItem>
              </List>
            </StackLeft>
          </ListFilterSec>
          {array?.length > 0 &&
            array?.map((item) => {
              return (
                <ClaimListItem>
                  <ListCradPaper>
                    <CardStack>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box className="tokenTitleSec">
                            <Box>
                              <Typography variant="h3">
                                {item?.title}
                              </Typography>
                              <Typography variant="caption">
                                {item?.subtitle}
                              </Typography>
                            </Box>
                            <Button className="addToken">
                              <Box className="iconGroup">
                                <AddIcon />
                                <Image
                                  src={assest.sky_cat}
                                  width={35}
                                  height={35}
                                  alt="icon"
                                />
                              </Box>
                              <Typography variant="body2">Add Token</Typography>
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <ClaimBox direction="row">
                            <Box
                              className={
                                item?.isDisable == false && !item?.HoureText
                                  ? "claimedCount white_text_wrap"
                                  : "claimedCount"
                              }
                            >
                              <Typography variant="subtitle1">
                                {item?.claim}
                              </Typography>
                              <Typography variant="h4">
                                {item?.count1}
                              </Typography>
                            </Box>
                            <Box
                              className={
                                item?.isDisable == false && !item?.HoureText
                                  ? "claimedCount white_text_wrap"
                                  : "claimedCount"
                              }
                            >
                              <Typography variant="subtitle2">
                                Left:
                                <span>Of total claimable 1.500.000.000</span>
                              </Typography>
                              <Typography variant="h4">
                                {item?.count2}
                              </Typography>
                            </Box>
                          </ClaimBox>
                        </Grid>
                        <Grid item xs={12}>
                          <TokenListClailm sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6.5}>
                                <Typography variant="body1">
                                  {item?.claimTimeText}
                                  <span>{item?.HoureText}</span>
                                  <strong>{item?.DateText}</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.5}>
                                {item?.isDisable === true ? (
                                  <CustomButton type="button">
                                    <Typography variant="body1">
                                      Claim
                                    </Typography>
                                  </CustomButton>
                                ) : null}
                              </Grid>
                            </Grid>
                          </TokenListClailm>
                        </Grid>
                      </Grid>
                    </CardStack>
                  </ListCradPaper>
                </ClaimListItem>
              );
            })}
        </ClaimList>
      </ClaimWrapper>
    </>
  );
};

export default ClaimMobile;
