import assest from "@/json/assest";
import styled from "@emotion/styled";
import { Button, List, ListItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import useChat from "@/hooks/custom/useChat";
import { defaultChatId } from "@/config/app";

const FixedButton = styled(Button)`
  font-family: "Manrope";
  font-weight: 700;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
      65.01% 281.3% at 49.5% 49.7%,
      rgba(92, 241, 255, 0.98) 0%,
      #028895 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid var(--activeColor);
  border-radius: 5px;
  padding: 10.5px 51px;
  transition: all 0.5s ease-in;
  min-width: 233px;
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
  }
  &:hover {
    background: transparent;
  }
  @media (max-width:599px) {
    display: none;
  }
`;
const ChatBox = styled(Box)`
  padding: 10px;
  background: radial-gradient(
      81.73% 113.97% at 70.79% 50.77%,
      rgba(0, 0, 0, 0.78) 0%,
      rgba(0, 0, 0, 0.5) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  min-width: 285px;
  .box_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    h4 {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: var(--white);
    }
  }
  .btn_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    li {
      padding: 0;
      display: block;
      width: auto;
      button {
        width: 20px;
        height: 20px;
        min-width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .box_list {
    overflow-y: auto;
    ul {
      padding: 0;
      max-height: 350px;
      li {
        flex-wrap: wrap;
        padding: 5px 0;
        cursor:pointer;
        .list_icon {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-basis: 35px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
          span {
            position: absolute;
            right: -4px;
            bottom: 4px;
            width: 10px;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background: transparent;
            &.blue_span {
              background: radial-gradient(
                74% 74% at 64% 26%,
                rgba(92, 241, 255, 0.98) 0%,
                #028895 100%
              );
            }
            &.red_span {
              background: radial-gradient(
                74% 74% at 64% 26%,
                rgba(255, 92, 131, 0.98) 0%,
                #95022e 100%
              );
            }
          }
        }
        .list_item_content {
          flex-basis: calc(100% - 35px);
          max-width: calc(100% - 35px);
          padding-left: 15px;
          h5 {
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 0.01em;
            color: var(--white);
            span {
              font-size: 10px;
              letter-spacing: 0.01em;
              color: var(--grey656565);
            }
          }
          p {
            font-size: 12px;
            color: var(--greyC2C2C2);
            &.unread_text {
              color: var(--activeColor);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .box_middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    .box_icon {
      width: 35px;
      height: 35px;
      flex-basis: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .box_list_content {
      flex-basis: calc(100% - 35px);
      max-width: calc(100% - 35px);
      padding-left: 15px;
      h5 {
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: var(--white);
      }
      p {
        font-size: 12px;
        color: var(--greyC2C2C2);
        span {
          font-size: 10px;
          letter-spacing: 0.01em;
          color: var(--grey656565);
        }
      }
    }
  }
`;
const ChatBoxWrapper = styled(Box)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: flex;
  align-items: flex-end;
`;
const ChatBoxPanel = styled(Box)`
  background: radial-gradient(
      81.73% 113.97% at 70.79% 50.77%,
      rgba(0, 0, 0, 0.78) 0%,
      rgba(0, 0, 0, 0.5) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  min-width: 325px;
  max-width: 375px;
  .left_panel_hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 20px;
  }
  .left_panel_hdr_left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .user_icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 35px;
  }
  .user_txt {
    flex-basis: calc(100% - 35px);
    max-width: calc(100% - 35px);
    padding-left: 15px;
    h5 {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: var(--white);
    }
    p {
      font-size: 12px;
      color: var(--greyC2C2C2);
    }
  }
  .left_panel_hdr_rgt {
    button {
      width: 20px;
      height: 20px;
      min-width: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      padding: 0;
    }
  }
  .text_block {
    display: inline-flex;
    padding: 10px 10px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50px 50px 0 50px;
    p {
      font-size: 12px;
      color: var(--grey50);
    }
  }
  .left_panel_chat {
    ul {
      li {
        padding: 5px 0 7px 0;
        position: relative;
        justify-content: flex-end;
        &.reciverList {
          justify-content: flex-start;
          .text_block {
            border-radius: 50px;
            background: rgba(255, 255, 255, 0.03);
          }
          .time_span {
            right: auto;
            left: 0;
          }
        }
      }
    }
    .sender_info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      cursor: pointer;
    }
    
    .sender_info img {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }
  }
  .chat_window {
    max-height: 325px; /* Adjust this value according to your desired height */
    overflow-y: auto;
  }
  .time_span {
    font-size: 10px;
    color: var(--grey7A7A7A);
    position: absolute;
    right: 0;
    top: -9px;
  }
`;
const ChatBoxLeft = styled(Box)`
margin-right: 20px;
`;
const ChatBoxInput = styled(Box)`
margin-top: 5px;

  .MuiInputBase-root {
    input {
      background: radial-gradient(
        81.73% 113.97% at 70.79% 50.77%,
        rgba(0, 0, 0, 0.78) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      border-radius: 5px;
      border: 0;
      height: 45px;
      color: #fff;
      line-height: 1;
      font-size: 14px;
    }
    fieldset {
      border: 1px solid rgba(255, 255, 255, 0.15);
      top: 0;
      legend {
        display: none;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    .MuiFormLabel-root {
      display: none;
    }
  }
  .chat_form{
    position: relative;
  }
  .send_btn{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    min-width: auto;
    padding: 0;
  }
`;
const ChatBoxRight = styled(Box)``;

export default function ButtonFixedAtBottom({
  showChatRooms,
  setShowChatRooms,
  showChatDialog,
  setShowChatDialog,
  chatId,
  setChatId,
  chatMessages,
  setChatMessages,
  chatRooms,
  setChatRooms,
  chatInput,
  setChatInput,
  submitMessage,
  handleKeyDown,
  createNewChat,
  address,
  chatWindowRef,
  handleOpenChatRooms,
  handleCloseChatRooms,
  handleOpenChatDialog,
  handleCloseChatDialog,
  getTruncatedSenderName,
  clickGeneralUser,
  getChatRoomImageUrl,
  getChatRoomUsername,
  getChatMessageImageUrl,
}) {

  return (
    <>
      <ChatBoxWrapper>
        {
          showChatDialog && (
            <ChatBoxLeft>
            <ChatBoxPanel>
              <div className="left_panel_hdr">
                <div className="left_panel_hdr_left">
                  <i className="user_icon">
                    <Image
                      src={getChatRoomImageUrl(chatId)}
                      alt="img"
                      width={35}
                      height={35}
                      style={{borderRadius: "50%"}}
                    />
                  </i>
                  <div className="user_txt">
                    <Typography variant="h5">{getChatRoomUsername(chatId)}</Typography>
                    {/*
                      <Typography variant="body1">
                      Last seen a minute ago
                    </Typography>
                  */}
                  </div>
                </div>
                <div className="left_panel_hdr_rgt">
                  <Button onClick={handleCloseChatDialog}>
                    <Image
                      src={assest.cross_icon}
                      alt="img"
                      width={20}
                      height={20}
                    />
                  </Button>
                </div>
              </div>
              <div className="left_panel_chat chat_window" >
                <List ref={chatWindowRef}>
                  {chatMessages.map((data, index) => {
                    return (
                      <ListItem
                        key={index}
                        className={`${
                          // @ts-ignore
                          data?.isSenderMe
                            ? "senderList"
                          // @ts-ignore
                            : !data?.isSenderMe
                            ? "reciverList"
                            : ""
                        }`}
                        style={{marginBottom: "5px"}}
                      >
                        {
                          // @ts-ignore
                          !data.isSenderMe && chatId === defaultChatId && (
                            // @ts-ignore
                            <div className="sender_info" onClick={() => clickGeneralUser(data.senderAddress)}>
                              <img src={getChatMessageImageUrl(data)} alt="Profile" />
                              {/* @ts-ignore */}
                              <Typography variant="body2">{getTruncatedSenderName(data?.sender)}</Typography>
                            </div>
                          )
                        }
                        {/* @ts-ignore */}
                        <span className="time_span">{data?.timeAgo}</span> 
                        <div className="text_block">
                          {/* @ts-ignore */}
                          <Typography variant="body1">{data?.message}</Typography>
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </ChatBoxPanel>
            <ChatBoxInput>
              <Box component="form" noValidate autoComplete="off" className="chat_form">
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <Button className="send_btn" onClick={submitMessage}>
                <Image src={assest.send_icon} alt="img" height={23} width={23}/>
              </Button>
              </Box>
              
            </ChatBoxInput>
          </ChatBoxLeft>
          )
        }
       
        <ChatBoxRight>
          {showChatRooms && (
            <ChatBox>
              <div className="box_head">
                <Typography variant="h4">Your Chats (Beta)</Typography>
                <List className="btn_box">
                  <ListItem>
                    {/*<Button>
                      <Image
                        src={assest.wht_search_icon}
                        alt="icon"
                        width={20}
                        height={20}
                      />
                    </Button>
                  */}
                  </ListItem>
                  <ListItem>
                    <Button onClick={handleCloseChatRooms}>
                      <Image
                        src={assest.cross_icon}
                        alt="icon"
                        width={20}
                        height={20}
                      />
                    </Button>
                  </ListItem>
                </List>
              </div>
              <div className="box_middle">
                <i className="box_icon">
                  <Image
                    src={assest.pulse_icon_new}
                    alt="icon"
                    width={35}
                    height={35}
                  />
                </i>
                <div 
                  className="box_list_content" 
                  onClick={() => handleOpenChatDialog(defaultChatId)}
                  style={{cursor: "pointer"}}
                >
                  <Typography variant="h5">Pulsefinity General</Typography>
                  {/*
                  <Typography variant="body1">
                    Username posted <span>. Just now</span>
                  </Typography>
                */}
                </div>
              </div>
              <div className="box_list">
                <List>
                  {chatRooms && chatRooms.map((data, index) => {
                    return (
                      <ListItem key={index} onClick={() => handleOpenChatDialog(data.id)}>
                        <i className="list_icon">
                          <Image
                            src={getChatRoomImageUrl(data.id)}
                            alt="img"
                            height={35}
                            width={35}
                          />
                          {/*
                          <span
                            className={`${
                              data?.status === "online"
                                ? "blue_span"
                                : data?.status === "Inactive"
                                ? "red_span"
                                : ""
                            }`}
                          ></span>
                          */}
                        </i>
                        <div className="list_item_content">
                          <Typography variant="h5">
                            {/** @ts-ignore */}
                            {getChatRoomUsername(data.id)}
                            {/* <span> . {data?.time}</span> */}
                          </Typography>
                          {/*
                          <Typography
                            variant="body1"
                            className={`${data?.unread && "unread_text"}`}
                          >
                            {data?.details}
                          </Typography>
                        */}
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </ChatBox>
          )}

          <FixedButton className="fixedBtn" onClick={handleOpenChatRooms}>
            Join Chat
            <span>
              {showChatRooms ? (
                <Image
                  src={assest.open_chat_icon}
                  alt="icon"
                  width={20}
                  height={20}
                />
              ) : (
                <Image
                  src={assest.msg_icon}
                  alt="icon"
                  width={20}
                  height={20}
                />
              )}
            </span>
          </FixedButton>
        </ChatBoxRight>
      </ChatBoxWrapper>
    </>
  );
}
