import axios from 'axios';
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from 'wagmi'
import useFirebase from "./useFirebase";
import { defaultChatId } from "@/config/app"
import { useSelector } from 'react-redux';

import { useCollection } from 'react-firebase-hooks/firestore';
import {      
    collection, 
    query, 
    addDoc, 
    where,
    orderBy, 
    limit,
    Query,
    getDocs,
    or,
} from 'firebase/firestore';
import { startAfter } from "firebase/database";
import { httpsCallable } from 'firebase/functions';
import { 
  isObjectExists,
  isArrayExists,
  timeSince,
  getSlicedWallet
} from "@/utils/index"
import {
  API_URL,
  API_ENDPOINTS,
} from "@/config/api"

export default function useChat({
}) {
  const [showChatRooms, setShowChatRooms] = useState(false);
  const [showChatDialog, setShowChatDialog] = useState(false);

  const [chatId, setChatId] = useState(defaultChatId);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [chatInput, setChatInput] = useState("");

  const [firestore, functions] = useFirebase();
  const { address } = useAccount();
  // @ts-ignore
  const avatarUrl = useSelector((state) => state?.user?.user.avatar_url);
  // @ts-ignore
  const username = useSelector((state) => state?.user?.user.username);

  const [
    message_documents, 
    , 
    message_documents_error
  ] = useCollection(
    query(
      collection(firestore, "messages"), 
      orderBy("timestamp"), 
      where("chat_id", '==', chatId),
      limit(5000),
    ), 
    { 
      snapshotListenOptions: { includeMetadataChanges: true } 
    }
  );

  const [
    chat_room_documents, 
    , 
    chat_room_documents_error
  ] = useCollection(
    query(
      collection(firestore, "chatRooms"), 
      orderBy("created_timestamp"), 
      or(
        where('user1_address', '==', address?.toLowerCase() || ''),
        where('user2_address', '==', address?.toLowerCase() || ''),
      ),
      limit(5000),
    ), 
    { 
      snapshotListenOptions: { includeMetadataChanges: true } 
    }
  );

  function loadMessages() {        
    if (isObjectExists(message_documents) && !message_documents_error) {
        let docs = message_documents.docs;
        let messageDocs = docs.map(d => ({ ...d.data(), id: d.id }));
        messageDocs.sort((a,b) => a.timestamp?.seconds - b.timestamp?.seconds)
        setChatMessages(mapChatMessages(messageDocs))
    } else if (message_documents_error) {
      console.log("message_documents_error", message_documents_error)
    }
  }

  async function loadChatRooms() {
    if (isObjectExists(chat_room_documents) && !chat_room_documents_error) {
        let docs = chat_room_documents.docs;
        let chatRoomDocs = docs.map(d => ({ ...d.data(), id: d.id }));
        chatRoomDocs.sort((a,b) => a.created_timestamp?.seconds - b.created_timestamp?.seconds)
        setChatRooms(await mapChatRooms(chatRoomDocs))
    } else if (chat_room_documents_error) {
      console.log("chat_room_documents_error", chat_room_documents_error)
    }
  }

  function mapChatMessages(messages) {
    return messages.map(messageDoc => {
      const {
        senderAddress,
        timestamp
      } = messageDoc;

      const isSenderMe = senderAddress && address && senderAddress.toLowerCase() === address.toLowerCase();
      const timeAgo = timeSince(timestamp?.seconds || 0);

      return {
        isSenderMe,
        timeAgo,
        ...messageDoc
      }
    });
  }

  async function mapChatRooms(rooms) {
    const mappedChatRooms = []
    for (let i = 0; i < rooms.length; i++) {
      const roomDoc = rooms[i];
      const {
        user1_address,
        user2_address,
        created_timestamp
      } = roomDoc;

      const isUser1Me = user1_address && address && user1_address.toLowerCase() === address.toLowerCase();
      const timeAgo = timeSince(created_timestamp?.seconds || 0);

      let counterParty = isUser1Me ? user2_address : user1_address;
      let username;
      let userAvatarUrl;

      try {
        const user = await axios.get(`${API_URL}${API_ENDPOINTS.USER.GET.url}?wallet_address=${counterParty}`);
        if (
          isObjectExists(user) && 
          isObjectExists(user.data) &&
          user.data.success && 
          isObjectExists(user.data.data
        )) {
          username = user.data.data.username;
          userAvatarUrl = user.data.data.avatar_url;
        }
      } catch (e) {
        console.log("e", e)
      }

      if (ethers.utils.isAddress(counterParty)) {
        counterParty = getSlicedWallet(counterParty, 12);
      }

      mappedChatRooms.push({
        counterParty,
        timeAgo,
        username,
        userAvatarUrl,
        ...roomDoc
      });
    }

    return mappedChatRooms;
  }

  useEffect(() => {
    loadMessages();
  }, [message_documents, message_documents_error]);

  useEffect(() => {
    loadChatRooms();
  }, [chat_room_documents, chat_room_documents_error]);

  const submitMessage = async (e) => {
    if (e && e.preventDefault)
      e.preventDefault();

    const isInputNotNull = chatInput && chatInput !== "";
    if (!isInputNotNull) {
      return;
    }

    const isInputTooLong = chatInput.length > 1000;
    if (isInputTooLong) {
      return;
    }

    const message = chatInput.trim();
    setChatInput("");

    if (message && message !== "") {
      try {
        /*const res = await axios.post("https://us-central1-pulsefinity.cloudfunctions.net/submitChatMessage", {
          sender: username && username !== "" ? username : address?.toLowerCase() || '',
          senderAddress: address?.toLowerCase() || '',
          avatarUrl,
          chatId: chatId,
          message: message,
        })

        if (res && res.data && res.data.success) {
          console.log("success")
        } else {
          console.log(res)
        }*/
        const newMessageDocument = {
          sender: username && username !== "" ? username : address?.toLowerCase() || '',
          senderAddress: address?.toLowerCase() || '',
          avatarUrl,
          chat_id: chatId,
          message: message,
          timestamp: { seconds: Math.floor(Date.now() / 1000)}
        };
        await addDoc(collection(firestore, "messages"), newMessageDocument);
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      submitMessage(e)
    }
  };

  const createNewChat = async (user2Address) => {
    const lowCaseUser1Address = address.toLowerCase();
    const lowCaseUser2Address = user2Address.toLowerCase();
    const existingChatRoom = chatRooms.find(room => {
      return (
        (room.user1_address === lowCaseUser1Address && room.user2_address === lowCaseUser2Address) ||
        (room.user1_address === lowCaseUser2Address && room.user2_address === lowCaseUser1Address)
      )
    });
    
    if (existingChatRoom) {
      setChatId(existingChatRoom.id);
      return existingChatRoom.id;
    }

    try {
      /*const res = await axios.post("https://us-central1-pulsefinity.cloudfunctions.net/createNewChat", {
        user1Address: lowCaseUser1Address,
        user2Address: lowCaseUser2Address,
      })

      if (res && res.data) {
        console.log(res.data)
        setChatId(res.data);
      } else {
        console.log(res)
      }*/
      const newChatroomDocument = {
        user1_address: lowCaseUser1Address,
        user2_address: lowCaseUser2Address,
        created_timestamp: { seconds: Math.floor(Date.now() / 1000)}
      };
      const res = await addDoc(collection(firestore, "chatRooms"), newChatroomDocument);
      if (
        res &&
        res._key &&
        res._key.path &&
        res._key.path.segments &&
        res._key.path.segments.length > 1
      ) {
        const newChatId = res._key.path.segments[1];
        setChatId(newChatId);
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    showChatRooms,
    setShowChatRooms,
    showChatDialog,
    setShowChatDialog,
    chatId,
    setChatId,
    chatMessages,
    setChatMessages,
    chatRooms,
    setChatRooms,
    chatInput,
    setChatInput,
    submitMessage,
    handleKeyDown,
    createNewChat,
    address
  }
}