import assest from "@/json/assest";
import styled from "@emotion/styled";
import { Button, List, ListItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";

const ChatBoxPanel = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  z-index: 999;
  background: radial-gradient(
      212.27% 204.17% at -11.6% 140.93%,
      #291c95 0%,
      #080520 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  backdrop-filter: blur(4px);
  padding: 15px;
  min-width: 285px;

  .left_panel_hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  .left_panel_hdr_left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      min-width: auto;
    }
  }
  .user_icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 35px;
  }
  .user_txt {
    flex-basis: calc(100% - 35px);
    max-width: calc(100% - 35px);
    padding-left: 15px;
    h5 {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: var(--white);
    }
    p {
      font-size: 12px;
      color: var(--greyC2C2C2);
    }
  }
  .left_panel_hdr_btm {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .left_panel_hdr_rgt {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    button {
      width: 20px;
      height: 20px;
      min-width: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      padding: 0;
      &:nth-child(1) {
        margin-right: 12px;
      }
    }
  }
  .text_block {
    display: inline-flex;
    padding: 10px 10px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50px 50px 0 50px;
    p {
      font-size: 12px;
      color: var(--grey50);
    }
  }
  .left_panel_chat_wrapper{
    height: calc(100vh - 250px);
    overflow: auto;
  }

  .left_panel_chat {

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    ul {
      width: 100%;
      li {
        padding: 5px 0 7px 0;
        position: relative;
        justify-content: flex-end;
        &.reciverList {
          justify-content: flex-start;
          .text_block {
            border-radius: 50px;
            background: rgba(255, 255, 255, 0.03);
          }
          .time_span {
            right: auto;
            left: 0;
          }
        }
      }
    }
    .sender_info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      cursor: pointer;
    }
    
    .sender_info img {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }
  }
  .time_span {
    font-size: 10px;
    color: var(--grey7A7A7A);
    position: absolute;
    right: 0;
    top: -9px;
  }
`;
const ChatBoxInput = styled(Box)`
    margin-top: 5px;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    padding: 10px;
 
  .MuiInputBase-root {
    input {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 76px;
      border: 0;
      height: 45px;
      color: #fff;
      line-height: 1;
      font-size: 16px;
    }
    fieldset {
      border: 0;
      top: 0;
      legend {
        display: none;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    flex-basis: calc(100% - 45px);
    max-width: calc(100% - 45px);
    padding-right: 15px;
    .MuiFormLabel-root {
      display: none;
    }
  }
  .chat_form {
    position: relative;
    display: flex;
    align-items: center;
  }
  .send_btn {
    min-width: auto;
    padding: 0;
    width: 45px;
    height: 45px;
    flex-basis: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #525060;
    border-radius: 50%;
    img{
        filter: brightness(0) invert(1);
    }
  }
`;

interface mobileProps {
  stepZero: any;
  stepPrev: any;
  chatId: string;
  defaultChatId: string;
  chatMessages: [{
    isSenderMe: boolean;
    senderAddress: string;
    sender: string;
    timeAgo: string;
    message: string;
  }];
  getChatRoomImageUrl: Function;
  getChatRoomUsername: Function;
  handleCloseChatDialog: Function;
  getTruncatedSenderName: Function;
  clickGeneralUser: Function;
  getChatMessageImageUrl: Function;
  chatInput: string;
  setChatInput: Function;
  handleKeyDown: Function;
  submitMessage: Function;
  chatWindowRef: any;
}

export default function MobileChat(props: mobileProps) {

  const closeChatArrow = () => {
    props.handleCloseChatDialog();
    props.stepPrev();
  }

  const closeChatCross = () => {
    props.handleCloseChatDialog();
    props.stepZero();
  }

  return (
    <>
      <ChatBoxPanel>
        <Box className="left_panel_hdr">
          <Box className="left_panel_hdr_left">
            <Button onClick={closeChatArrow}>
              <Image 
                src={assest.prevArrw} 
                alt="img" 
                width={20} 
                height={20} 
              />
            </Button>
          </Box>
          <Box className="left_panel_hdr_rgt">
            {/*<Button>
              <Image
                src={assest.wht_search_icon}
                alt="img"
                width={20}
                height={20}
              />
            </Button>*/}
            <Button onClick={closeChatCross}>
              <Image 
                src={assest.cross_icon} 
                alt="img" 
                width={20} 
                height={20} 
              />
            </Button>
          </Box>
        </Box>
        <Box className="left_panel_hdr_btm">
          <i className="user_icon">
            <Image
              src={props.getChatRoomImageUrl(props.chatId)}
              alt="img"
              width={35}
              height={35}
              style={{borderRadius: "50%"}} 
            />
          </i>
          <Box className="user_txt">
            <Typography variant="h5">{props.getChatRoomUsername(props.chatId)}</Typography>
            {/*<Typography variant="body1">Last seen a minute ago</Typography>*/}
          </Box>
        </Box>
        <Box className="left_panel_chat_wrapper">
        <Box className="left_panel_chat">
          <List ref={props.chatWindowRef}>
            {props.chatMessages && props.chatMessages.map((data, index) => {
              return (
                <ListItem
                  key={index}
                  className={`${data?.isSenderMe
                    ? "senderList"
                    : !data?.isSenderMe
                      ? "reciverList"
                      : ""
                    }`}
                >
                  {
                    // @ts-ignore
                    !data.isSenderMe && props.chatId === props.defaultChatId && (
                    // @ts-ignore
                      <div className="sender_info" onClick={() => props.clickGeneralUser(data.senderAddress)}>
                        <img src={props.getChatMessageImageUrl(data)} alt="Profile" />
                        <Typography variant="body2">{props.getTruncatedSenderName(data?.sender)}</Typography>
                      </div>
                    )
                  }
                  <span className="time_span">{data?.timeAgo}</span>
                  <Box className="text_block">
                    <Typography variant="body1">{data?.message}</Typography>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
        </Box>
     
        <ChatBoxInput>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            className="chat_form"
          >
            <TextField
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              value={props.chatInput}
              onChange={(e) => props.setChatInput(e.target.value)}
              // @ts-ignore
              onKeyDown={props.handleKeyDown}
            />
            {/* @ts-ignore */}
            <Button className="send_btn" onClick={props.submitMessage}>
              <Image src={assest.send_icon} alt="img" height={23} width={23} />
            </Button>
          </Box>
        </ChatBoxInput>
      </ChatBoxPanel>
    </>
  );
}
