import assest from "@/json/assest";
import styled from "@emotion/styled";
import { Button, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import React, { useEffect } from "react";

interface mobileProps {
  stepZero: any;
  stepNext: any;
  defaultChatId: string;
  chatRooms: [{
    id: string;
  }];
  getChatRoomImageUrl: Function;
  getChatRoomUsername: Function;
  handleOpenChatDialog: Function;
  handleCloseChatRooms: Function;
}

const ChatBox = styled(Box)`
  padding: 15px;
  background: radial-gradient(
      212.27% 204.17% at -11.6% 140.93%,
      #291c95 0%,
      #080520 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  backdrop-filter: blur(4px);
  min-width: 285px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  z-index: 999;

  .box_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    h4 {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: var(--white);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
      }
    }
  }
  .btn_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    li {
      padding: 0;
      display: block;
      width: auto;
      button {
        width: 20px;
        height: 20px;
        min-width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .box_list {
    overflow-y: auto;
    height:calc(100% - 103px);
    ul {
      padding: 0;
      max-height: 100vh;
      li {
        flex-wrap: wrap;
        padding: 5px 0;
        cursor: pointer;
        .list_icon {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-basis: 35px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
          span {
            position: absolute;
            right: -4px;
            bottom: 4px;
            width: 10px;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background: transparent;
            &.blue_span {
              background: radial-gradient(
                74% 74% at 64% 26%,
                rgba(92, 241, 255, 0.98) 0%,
                #028895 100%
              );
            }
            &.red_span {
              background: radial-gradient(
                74% 74% at 64% 26%,
                rgba(255, 92, 131, 0.98) 0%,
                #95022e 100%
              );
            }
          }
        }
        .list_item_content {
          flex-basis: calc(100% - 35px);
          max-width: calc(100% - 35px);
          padding-left: 15px;
          h5 {
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 0.01em;
            color: var(--white);
            span {
              font-size: 10px;
              letter-spacing: 0.01em;
              color: var(--grey656565);
            }
          }
          p {
            font-size: 12px;
            color: var(--greyC2C2C2);
            &.unread_text {
              color: var(--activeColor);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .box_middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    .box_icon {
      width: 35px;
      height: 35px;
      flex-basis: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .box_list_content {
      flex-basis: calc(100% - 35px);
      max-width: calc(100% - 35px);
      padding-left: 15px;
      h5 {
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: var(--white);
      }
      p {
        font-size: 12px;
        color: var(--greyC2C2C2);
        span {
          font-size: 10px;
          letter-spacing: 0.01em;
          color: var(--grey656565);
        }
      }
    }
  }
`;

export default function ChatList(props: mobileProps) {

  const onCloseChatRooms = () => {
    props.handleCloseChatRooms();
    props.stepZero();
  }

  const openChatRoom = (newChatId) => {
    const opened = props.handleOpenChatDialog(newChatId);
    if (opened)
      props.stepNext();
  }

  return (
    <>
      <ChatBox>
        <div className="box_head">
          <Typography variant="h4">
            Your Chats (Beta)
            {/*<span>
              <Image
                src={assest.open_chat_icon}
                alt="icon"
                width={25}
                height={25}
            />
            </span>*/}
          </Typography>
          <List className="btn_box">
            <ListItem>
              {/*<Button>
                <Image
                  src={assest.wht_search_icon}
                  alt="icon"
                  width={20}
                  height={20}
                />
          </Button>*/}
            </ListItem>
            <ListItem>
              <Button onClick={onCloseChatRooms}>
                <Image
                  src={assest.cross_icon}
                  alt="icon"
                  width={20}
                  height={20}
                />
              </Button>
            </ListItem>
          </List>
        </div>
        <div className="box_middle">
          <i className="box_icon">
            <Image
              src={assest.pulse_icon_new}
              alt="icon"
              width={35}
              height={35}
            />
          </i>
          <div 
            className="box_list_content"
            onClick={() => openChatRoom(props.defaultChatId)}
          >
            <Typography variant="h5">Pulsefinity General</Typography>
            {/*<Typography variant="body1">
              Username posted <span>. Just now</span>
            </Typography>*/}
          </div>
        </div>
        <div className="box_list">
          <List>
            {props.chatRooms && props.chatRooms.map((data, index) => {
              return (
                <ListItem key={index} onClick={() => openChatRoom(data.id)}>
                  <i className="list_icon">
                    <Image src={props.getChatRoomImageUrl(data.id)} alt="img" height={35} width={35} />
                    {/*<span
                      className={`${data?.status === "online"
                        ? "blue_span"
                        : data?.status === "Inactive"
                          ? "red_span"
                          : ""
                        }`}
                      ></span>*/}
                  </i>
                  <div className="list_item_content">
                    <Typography variant="h5">
                      {props.getChatRoomUsername(data.id)}
                      {/*<span> . {data?.time}</span>*/}
                    </Typography>
                    {/*<Typography
                      variant="body1"
                      className={`${data?.unread && "unread_text"}`}
                    >
                      {data?.details}
                    </Typography>*/}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
      </ChatBox>
    </>
  );
}
