import CustomCheckBox from "@/ui/Checkbox/CustomCheckBox";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Calendersec = styled(Box)`
  @media (max-width: 599px) {
    padding: 125px 15px 15px;
    background: url(/assets/images/calender_back.png) no-repeat center;
    background-size: 100% 140%;
    min-width: 285px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: calc(100vh - 52px);
    z-index: 99;
    overflow-y: auto;
  }
  padding-top: 48px;
  .calender_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 599px) {
      justify-content: center;
    }
    h3 {
      font-weight: 800;
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: 0.01em;
      color: var(--white);
    }
    .checkarea {
      display: flex;
      align-items: center;
      @media (max-width: 599px) {
        display: none;
      }
      h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        color: var(--white);
      }
    }
  }
  .calender_sec {
    margin-top: 15px;
    table {
      border-spacing: 5px !important;
    }
    .fc-view-harness {
      @media (max-width: 599px) {
        height: 350px !important;
      }
    }
    .fc-header-toolbar {
      @media (max-width: 599px) {
        justify-content: space-between;
        .fc-toolbar-chunk {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
    .fc-scrollgrid {
      border: none;
      thead {
        tr {
          th {
            border: none;
            text-align: end;
            a {
              font-weight: 400;
              font-size: 16.3636px;
              /* line-height:2; */
              text-align: right;
              letter-spacing: 0.02em;
              color: rgba(248, 247, 250, 0.64);
              @media (max-width: 599px) {
                font-weight: 700;
                font-size: 12.3636px;
                color: var(--activeColor);
                padding: 15px 10px;
              }
            }
          }
        }
      }
      tbody {
        .fc-scrollgrid-sync-table {
          border-collapse: initial;
          border-spacing: 0px;

          tbody {
            .fc-day {
              border: 1.36364px solid var(--primary514f66);
              border-radius: 5px;
              @media (max-width: 599px) {
                border: 0;
              }
            }
            .fc-day-other {
              opacity: 0.32;
              @media (max-width: 599px) {
                border: 0;
                opacity: 1;
                .fc-daygrid-day-frame {
                  background: var(--primary040214);

                  .fc-daygrid-day-top {
                    opacity: 1;
                    .fc-daygrid-day-number {
                      color: var(--grey606060);
                    }
                  }
                }
              }
            }
            @media (max-width: 599px) {
              .fc-daygrid-day-frame {
                width: 100%;
                height: 41.69px;
                background: var(--primary0e0e22);
                border-radius: 6.28593px;
                margin: 0 auto;
                &::before {
                  display: none;
                }
                .fc-daygrid-day-top {
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                }
              }
              .fc-daygrid-day-events {
                display: none;
              }
              .fc-daygrid-day-bottom {
                display: none;
              }
            }
          }
        }
      }
    }
    .fc-toolbar-title {
      font-weight: 800;
      font-size: 20px;
      text-align: center;
      letter-spacing: 0.01em;

      color: var(--white);
    }
    .fc-daygrid-day-number {
      color: var(--white);
      font-size: 20px;
      margin-right: 5px;
      font-weight: 600;
      @media (max-width: 599px) {
        color: var(--white);
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        letter-spacing: -0.035em;
        /* color: #FFFFFF; */
      }
    }
    .fc .fc-daygrid-day.fc-day-today {
      background-color: #173c40;
      border: 2px solid var(--activeColor) !important;
    }
    .fc-daygrid-event {
      .fc-daygrid-event-dot {
        display: none;
      }
      .fc-event-time {
        color: var(--white);
      }
      .fc-event-title {
        color: var(--white);
      }
    }
    .fc .fc-scrollgrid-section,
    .fc .fc-scrollgrid-section table,
    .fc .fc-scrollgrid-section > td {
      border: 0;
    }
    .fc-dayGridMonth-button {
      display: none;
    }
    .fc-button-primary {
      background-color: var(--activeColor) !important;
      border-color: transparent !important;
      &:active {
        background-color: var(--activeColor);
        border-color: transparent !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .fc-button-primary:not(:disabled):active:focus {
      box-shadow: none;
    }

    .fc-day-sun,
    .fc-day-sat {
      .fc-daygrid-day-frame {
        background: #161635 !important;
      }
    }
  }
  @media (min-width: 600px){
    .fc {
    .fc-view-harness {
      height: auto !important;
      > .fc-view {
        position: static;
        .fc-scroller {
          overflow: inherit !important;
          position: static;
        }
      }
    }
  }
  }

`;
const Calender = () => {
  const events = [] //[{ title: "Meeting", start: new Date() }];
  return (
    <Calendersec>
      <Box className="calender_head">
        <Typography variant="h3">My Calendar</Typography>
        {/*<Box className="checkarea">
          <Typography variant="h4">Display:</Typography>
          <FormControlLabel
            value="start"
            control={<CustomCheckBox />}
            label="Token Claims"
            labelPlacement="start"
          />
          <FormControlLabel
            value="start"
            control={<CustomCheckBox />}
            label="Dao Starts"
            labelPlacement="start"
          />
          <FormControlLabel
            value="start"
            control={<CustomCheckBox />}
            label="Dao Ends"
            labelPlacement="start"
          />
  </Box>*/}
      </Box>
      <Box className="calender_sec">
        <FullCalendar
          initialView="dayGridMonth"
          headerToolbar={{
            right: "prev,next",
            center: "title",
            left: "dayGridMonth",
          }}
          themeSystem="Simplex"
          plugins={[dayGridPlugin]}
          events={events}
        />
      </Box>
    </Calendersec>
  );
};

export default Calender;
