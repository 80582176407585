import dynamic from "next/dynamic";
import React, { Suspense, useEffect, useState } from "react";
import NextProgress from "next-progress";
import HeaderSkeleton from "@/ui/Skeletons/HeaderSkeleton";
import useOnlineStatus from "@/hooks/useDetectOnline";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import ButtonFixedAtBottom from "@/components/ButtonFixedAtBottom/ButtonFixedAtBottom";
import MobileMenu from "@/components/MobileMenu/MobileMenu";
import ChatWelcome from "@/components/MobileChat/ChatWelcome";
import ChatList from "@/components/MobileChat/ChatList";
import MobileChat from "@/components/MobileChat/MobileChat";
import ProfileWallet from "@/components/ProfileWallet/ProfileWallet";
import ClaimMobile from "@/components/ClaimMobile/ClaimMobile";
import Calender from "@/components/Calender/Calender";

import assest from "@/json/assest";
import useChat from "@/hooks/custom/useChat";
import { defaultChatId } from "@/config/app";

const Header = dynamic(() => import("../Header/Header"), { suspense: true });
const Footer = dynamic(() => import("../Footer/Footer"), { suspense: true });

interface wrapperProps {
  children: JSX.Element | JSX.Element[];
  uppersec?: boolean;
}

const StarWrapper = styled(Box)`
  @media (max-width: 599px) {
    .star_wrapper {
      position: fixed;
      left: 0;
      top: 0;
      background: url("/assets/images/dotteBg.png") no-repeat center top;
      background-size: cover;
      background-attachment: fixed;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
    }
  }
`;

const Wrapper = (props: wrapperProps) => {

  const { children, uppersec } = props;

  const [step, setStep] = useState(0);
  const [show, setShow] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [showCalender, setShowCalender] = useState(false);

  const nextStep = () => {
    setStep(step + 1);
    setShow(false);
    setShowClaim(false);
    setShowCalender(false);
  };

  const stepMakeZero = () => {
    setStep(0);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const openDialog = () => {
    setShow(!show);
    setStep(0);
    setShowClaim(false);
    setShowCalender(false);
  };

  const toggleClaim = () => {
    setShowClaim(!showClaim);
    setShow(false);
    setStep(0);
    setShowCalender(false);
  };

  const toggleCalender = () => {
    setShowCalender(!showCalender);
    setShow(false);
    setShowClaim(false);
    setStep(0);
  };

  useOnlineStatus();

  useEffect(()=>{
    if(step === 2 || step === 3){
      document.body.classList.add("hidden")
    }
  
  },[step]);

  const {
    showChatRooms,
    setShowChatRooms,
    showChatDialog,
    setShowChatDialog,
    chatId,
    setChatId,
    chatMessages,
    setChatMessages,
    chatRooms,
    setChatRooms,
    chatInput,
    setChatInput,
    submitMessage,
    handleKeyDown,
    createNewChat,
    address
  } = useChat({});
  
  const chatWindowRef = React.useRef(null);
  React.useEffect(() => {
    if (chatWindowRef.current) {
      // @ts-ignore
      chatWindowRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [showChatDialog, chatId, chatMessages]);

  const handleOpenChatRooms = () => {
    if (!address) return false;
    setShowChatRooms(true);
    return true;
  };
  const handleCloseChatRooms = () => {
    setShowChatRooms(false);
    handleCloseChatDialog();
  };
  const handleOpenChatDialog = (newChatId) => {
    if (!address) return false;
    if (newChatId !== chatId)
      setChatMessages([])
    setChatId(newChatId);
    setShowChatDialog(true);
    return true;
  };
  const handleCloseChatDialog = () => {
    setShowChatDialog(false);
  };

  const getTruncatedSenderName = (senderName) => {
    if (senderName.length > 10) {
      return senderName.substring(0, 10) + "...";
    }
    return senderName;
  }

  const clickGeneralUser = async (senderAddress) => {
    await createNewChat(senderAddress);
  }

  const getChatRoomImageUrl = (chatId) => {
    if (!chatRooms) return assest.pulse_icon_new;
    if (chatId === defaultChatId) return assest.pulse_icon_new;
    // @ts-ignore
    const chatRoom = chatRooms.find((room) => room.id === chatId);
    if (!chatRoom) return assest.pulse_icon_new;
    // @ts-ignore
    return chatRoom.userAvatarUrl && chatRoom.userAvatarUrl !== "" ? chatRoom.userAvatarUrl : assest.user_icon_img;
  }

  const getChatRoomUsername = (chatId) => {
    if (!chatRooms) return "General";
    if (chatId === defaultChatId) return "General";
    // @ts-ignore
    const chatRoom = chatRooms.find((room) => room.id === chatId);
    if (!chatRoom) return "General";
    // @ts-ignore
    return chatRoom?.username !== "" ? chatRoom.username : getTruncatedSenderName(chatRoom.counterParty);
  }

  const getChatMessageImageUrl = (chatMessage) => {
    if (!chatMessage) return assest.user_icon_img;
    if (!chatMessage.avatarUrl) return assest.user_icon_img;
    if (chatMessage.avatarUrl === "") return assest.user_icon_img;
    return chatMessage.avatarUrl;
  }

  return (
    <>
      <NextProgress height={8} color="var(--activeColor)" />
      <Suspense fallback={<HeaderSkeleton />}>
        <Header />
      </Suspense>

      <ButtonFixedAtBottom
        showChatRooms={showChatRooms}
        setShowChatRooms={setShowChatRooms}
        showChatDialog={showChatDialog}
        setShowChatDialog={setShowChatDialog}
        chatId={chatId}
        setChatId={setChatId}
        chatMessages={chatMessages}
        setChatMessages={setChatMessages}
        chatRooms={chatRooms}
        setChatRooms={setChatRooms}
        chatInput={chatInput}
        setChatInput={setChatInput}
        submitMessage={submitMessage}
        handleKeyDown={handleKeyDown}
        createNewChat={createNewChat}
        address={address}
        chatWindowRef={chatWindowRef}
        handleOpenChatRooms={handleOpenChatRooms}
        handleCloseChatRooms={handleCloseChatRooms}
        handleOpenChatDialog={handleOpenChatDialog}
        handleCloseChatDialog={handleCloseChatDialog}
        getTruncatedSenderName={getTruncatedSenderName}
        clickGeneralUser={clickGeneralUser}
        getChatRoomImageUrl={getChatRoomImageUrl}
        getChatRoomUsername={getChatRoomUsername}
        getChatMessageImageUrl={getChatMessageImageUrl}
      />
      {children}

      <StarWrapper>
        <Box className="star_wrapper"></Box>
      </StarWrapper>

      <Suspense fallback={<HeaderSkeleton />}>
        <Footer uppersec={uppersec} />
      </Suspense>

      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileMenu
          stepNumber={step}
          chatOpen={nextStep}
          walletOpen={openDialog}
          toggleClaim={toggleClaim}
          toggleCalender={toggleCalender}
        />
      </Box>

      {step === 1 ? (
        <ChatWelcome 
          stepZero={stepMakeZero} 
          stepNext={nextStep} 
          handleOpenChatRooms={handleOpenChatRooms}
        />
      ) : step === 2 ? (
        <ChatList 
          stepZero={stepMakeZero} 
          stepNext={nextStep} 
          defaultChatId={defaultChatId}
          // @ts-ignore
          chatRooms={chatRooms}
          getChatRoomImageUrl={getChatRoomImageUrl}
          getChatRoomUsername={getChatRoomUsername}
          handleOpenChatDialog={handleOpenChatDialog}
          handleCloseChatRooms={handleCloseChatRooms}
        />
      ) : step === 3 ? (
        <MobileChat 
          stepPrev={prevStep} 
          stepZero={stepMakeZero} 
          chatId={chatId}
          defaultChatId={defaultChatId}
          // @ts-ignore
          chatMessages={chatMessages}
          getChatRoomImageUrl={getChatRoomImageUrl}
          getChatRoomUsername={getChatRoomUsername}
          handleCloseChatDialog={handleCloseChatDialog}
          getTruncatedSenderName={getTruncatedSenderName}
          clickGeneralUser={clickGeneralUser}
          getChatMessageImageUrl={getChatMessageImageUrl}
          chatInput={chatInput}
          setChatInput={setChatInput}
          handleKeyDown={handleKeyDown}
          submitMessage={submitMessage}
          chatWindowRef={chatWindowRef}
        />
      ) : null}
      
      {show && <ProfileWallet />}
      {showClaim && <ClaimMobile />}
      {showCalender && <Calender />}
    </>
  );
};

export default Wrapper;
