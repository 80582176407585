import { ethers } from "ethers";
import { 
  CONTRACT_WRITE_METHODS,
  CONTRACT_READ_METHODS,
  CONTRACT_EVENTS
} from "@/config/web3_contracts"

export function nowTimestamp() {
  return Math.floor(Date.now() / 1000);
}

// if number > 0 then return decimals
// if number string < 0 then return decimals + amount of zeros after dot
export function calculateDecimals(str, decimals=4) {
  const num = parseFloat(str);
  if (num > 1) return decimals;
  const dotSplit = str.split(".");
  if (dotSplit.length === 1) return decimals;
  const partAfterDot = str.split(".")[1];
  const leadingZeros = partAfterDot.match(/^0+/);
  if (!leadingZeros) return decimals;
  return decimals + leadingZeros[0].length;
}


export function formatNumberStringWithDecimals(str, decimals=4) {
  const dotSplit = str.split(".")
  if (dotSplit.length === 1) return str
  if (dotSplit.length > 1 && parseFloat(dotSplit[1]) === 0) return dotSplit[0]
  const partBeforeDot = str.split(".")[0]
  const partAfterDot = str.split(".")[1]
  const decimalsToUse = calculateDecimals(str, decimals)
  return `${partBeforeDot}.${partAfterDot.slice(0, decimalsToUse)}`
}

export function formatEtherString(etherString, decimals=4) {
  if (!etherString) return "0";
  if (ethers.BigNumber.isBigNumber(etherString)) {
    etherString = etherString.toString();
  } else if (typeof etherString !== "string") {
    return "0";
  }
  const formatted = ethers.utils.formatEther(etherString);
  return formatNumberStringWithDecimals(formatted, decimals);
}

function getBigNumbers(a,b) {
  let aBig = a;
  let bBig = b;
  if (typeof a === 'string') {
      aBig = ethers.BigNumber.from(a);
  }
  if (typeof b === 'string') {
      bBig = ethers.BigNumber.from(b);
  }
  return {
      aBig,
      bBig
  }
}

export function addBigNumbers(a,b) {
  const { aBig, bBig } = getBigNumbers(a,b);
  return aBig.add(bBig).toString();
}

export function subBigNumbers(a,b) {
  const { aBig, bBig } = getBigNumbers(a,b);
  return aBig.sub(bBig).toString();
}

export function mulBigNumbers(a,b) {
  const { aBig, bBig } = getBigNumbers(a,b);
  return aBig.mul(bBig).toString();
}

export function divBigNumbers(a,b) {
  const { aBig, bBig } = getBigNumbers(a,b);
  if (bBig.isZero()) return ethers.constants.Zero.toString();
  return aBig.div(bBig).toString();
}

export function bnLessThan(a,b) {
  const { aBig, bBig } = getBigNumbers(a,b);
  return aBig.lt(bBig);
}

export function getProfileOrWallet(profile) {
  if (!profile) return "";
  const {
    wallet_address,
    username
  } = profile;
  if (
    username && 
    username !== ""
  ) return username;
  return getSlicedWallet(wallet_address);
}

export function getSlicedWallet(wallet, sliceFactor=6) {
  if (!wallet) return "";
  if (!ethers.utils.isAddress(wallet)) return wallet;
  return `${wallet.slice(0,sliceFactor)}...`;
}

export function openLink(url, target="_blank") {
  window.open(url, target);
}

export function isNotUndefinedNumber(num) {
  return num !== null && num !== undefined && !isNaN(parseInt(num))
}

export function getContractAbi(contractName) {
  if (!contractName) return [];
  const writeMethods = CONTRACT_WRITE_METHODS[contractName];
  const readMethods = CONTRACT_READ_METHODS[contractName];
  const events = CONTRACT_EVENTS[contractName]

  let abi = []; 
  if (writeMethods) {
    Object.values(writeMethods).forEach(method => abi = abi.concat(method.abi))
  }
  if (readMethods) {
    Object.values(readMethods).forEach(method => abi = abi.concat(method.abi))
  }
  if (events) {
    Object.values(events).forEach(method => abi = abi.concat(method.abi))
  }

  return abi;
}

export function isNullOrUndefined(val) {
  return val === undefined || val === null;
}

export function isObjectExists(val) {
  return !isNullOrUndefined(val) && Object.keys(val).length > 0
}

export function isArrayExists(val) {
  return !isNullOrUndefined(val) && val.length > 0
}

export function timeSince(timestamp) {
  const now = nowTimestamp()
  const secondsPast = (now - timestamp);
  if (secondsPast < 60) {
    return "just now";
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + " minutes ago";
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + " hours ago";
  }
  if (secondsPast <= 2592000) {
    return parseInt(secondsPast / 86400) + " days ago";
  }
  if (secondsPast <= 31536000) {
    return parseInt(secondsPast / 2592000) + " months ago";
  }
  return parseInt(secondsPast / 31536000) + " years ago";
}

export function formatNumberString(str) {
  if (!str) return str;
  if (typeof str !== "string") return str;
  if (str.length < 4) return str;
  const num = parseInt(str);
  if (isNaN(num)) return str;
  if (num === 0) return "0.0...";
  if (num < 1000) return str;
  if (num < 1000000) return `${(num / 1000).toFixed(2)}K`;
  if (num < 1000000000) return `${(num / 1000000).toFixed(2)}M`;
  if (num < 1000000000000) return `${(num / 1000000000).toFixed(2)}B`;
  if (num < 1000000000000000) return `${(num / 1000000000000).toFixed(2)}T`;
  if (num < 1000000000000000000) return `${(num / 1000000000000000).toFixed(2)}Q`;
  return `${(num / 1000000000000000000).toFixed(2)}Q`;
}

export function generateRandomString(length=10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result;
}

export function formatCurrency(num) {
  if (!num) return "0";
  
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
}

export function makeValidUrl(url) {
  if (!url) return null;
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }
  return url;
}

export function vestingUnlockUnitValueToName(value) {
  switch(parseInt(value)) {
    case 1: return "Days";
    case 2: return "Weeks";
    case 3: return "Months";
  }
}


export function vestingUnlockUnitValueToDayjsUnit(value) {
  switch(parseInt(value)) {
    case 1: return "day";
    case 2: return "week";
    case 3: return "month";
  }
}