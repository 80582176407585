import assest from "@/json/assest";
import styled from "@emotion/styled";
import { List, ListItem, Typography, Container, Button } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import ChatWelcome from "../MobileChat/ChatWelcome";

interface chatProps {
  chatOpen: any;
  walletOpen: any;
  toggleClaim:any;
  stepNumber:number;
  toggleCalender:any;
}

const Mobilemenu = styled(Box)`
  .mobileMenu {
    background: url(/assets/images/mobile_btm_back.png) no-repeat center;
    background-size: 100% 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    .mbleInr {
      text-align: center;
      p {
        font-weight: 700;
        font-size: 8px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
      button {
        display: inline-block;
        padding: 0;
        min-width: auto;
      }
    }
    ul {
      display: flex;
      align-items: center;
      li {
        justify-content: center;
      }
    }
  }
`;

export default function MobileMenu(props: chatProps) {
  const { chatOpen ,stepNumber} = props;

  return (
    <>
      <Mobilemenu>
        <Box className="mobileMenu">
          <Container fixed>
            <List>
              <ListItem disablePadding>
                <Box className="mbleInr">
                  <Button onClick={props.walletOpen}>
                    <Image
                      alt=""
                      src={assest.wallet_icon}
                      width={17}
                      height={17}
                    />
                    <Typography variant="body1">Wallets</Typography>
                  </Button>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box className="mbleInr">
                  <Link href="profile">
                    <Image
                      alt=""
                      src={assest.dash_icon}
                      width={17}
                      height={17}
                    />
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box className="mbleInr">
                  <Button onClick={props.toggleCalender}>
                    <Image
                      alt=""
                      src={assest.calender_icon}
                      width={17}
                      height={17}
                    />
                    <Typography variant="body1">Calendar</Typography>
                  </Button>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box className="mbleInr">
                  <Button onClick={props.toggleClaim}>
                    <Image
                      alt=""
                      src={assest.bag_icon}
                      width={17}
                      height={17}
                    />
                    <Typography variant="body1">Claims</Typography>
                  </Button>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box className="mbleInr">
                  {
                    stepNumber != 0 ?
                    <Button onClick={chatOpen} disabled>
                    <Image
                      alt=""
                      src={assest.chat_icon}
                      width={17}
                      height={17}
                    />
                    <Typography variant="body1">Chat</Typography>
                  </Button>
                  :
                  <Button onClick={chatOpen}>
                  <Image
                    alt=""
                    src={assest.chat_icon}
                    width={17}
                    height={17}
                  />
                  <Typography variant="body1">Chat</Typography>
                </Button>
                  }
                 
                </Box>
              </ListItem>
            </List>
          </Container>
        </Box>
      </Mobilemenu>
    </>
  );
}
