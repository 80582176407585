import assest from '@/json/assest'
import TransparentButton from '@/ui/Buttons/TransparentButton'
import styled from '@emotion/styled'
import { Box, Button, List, ListItem, Stack, Typography } from '@mui/material'
import Image from 'next/image'
import React from 'react'
import { useAccount } from "wagmi";
import { 
  getSlicedWallet
} from "@/utils/index"

const WalletWrapper = styled(Box)`
    @media (max-width: 599px) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: calc(100vh - 52px);
        padding: 125px 15px 15px;
        background: url(/assets/images/mob-walletBg.png) no-repeat;
        background-size: cover;
        backdrop-filter: blur(4px);
        z-index: 99;
        overflow-y: auto;

        .right_head {
            h3 {
                font-weight: 800;
                font-size: 20px;
                line-height: 1.2;
                letter-spacing: 0.01em;
                color: var(--white);
            }
            p {
                font-size: 12px;
            }
        }

        .wallet_btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-size: cover;
            padding: 12px 8px;
            border-bottom: 1px solid #827fa6;
            position: relative;

            &:hover {
                .overlayButtonGroup {
                    visibility: visible;
                    opacity: 1;
                }

                .right_side {
                    opacity: 0;
                }
            }

            .overlayButtonGroup {
                justify-content: space-between;
                flex-wrap: wrap;
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 11;
                padding: 11px 0;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease-in;

                @media (max-width: 599px) {
                    justify-content: flex-end;
                }

                &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: radial-gradient(58.49% 78.31% at 50% 50%, rgba(217, 217, 217, 0.14) 0%, rgba(217, 217, 217, 0) 100%);
                z-index: 1;
                }

                button {
                    padding: 0;
                    min-width: 1px;
                    position: relative;
                    z-index: 11;
                    background: radial-gradient(73.31% 101.7% at 49.5% 49.7%, #615F74 0%, #3A3852 100%);
                    border: 1px solid #827FA6;
                    border-radius: 5px;

                    @media (max-width: 599px) {
                        width: 40px;
                        height: 40px;
                        align-items: center;
                    }

                    &:not(:last-child) {
                        @media (max-width: 599px) {
                            margin-right: 10px;
                        } 
                    }

                    .MuiButton-startIcon {
                        @media (max-width: 599px) {
                            display: inline-block;
                            margin: 0;
                        }
                    }

                    .MuiTypography-caption {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 1;

                        @media (max-width: 599px) {
                            display: none;
                        }
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            h4 {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.01em;
                color: var(--white);
                @media (max-width: 1199px) {
                    font-size: 13px;
                }

                @media (max-width: 599px) {
                    font-size: 16px;
                }
            }
            p {
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: var(--white);
            }
            .left_side {
                text-align: left;
                position: relative;
                     z-index: 99;
            }
            .right_side {
                display: flex;
                align-items: center;
                text-align: end;
                img {
                    margin-left: 8px;
                }
            }
            .MuiTouchRipple-root {
                display: none;
            }
        }

        .walletList {
            margin-top: 25px;
            .wallet_btn {
                &:first-child {
                border-top: 1px solid #827fa6 !important;  
            }
            }
        }
    }
`;

const MobViewInner = styled(Box)`
    text-align: center;

    h3 {
        font-weight: 800;
        font-size: 28px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        line-height: 1;
        margin-top: 20px;
    }

    ul {
        padding: 8px 0 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 599px) {
          justify-content: center;
        }
        li {
          padding: 0;
          width: auto;
          position: relative;
          font-weight: 400;
            font-size: 14px;

          &:not(:last-child) {
            padding-right: 10px;
            margin-right: 10px;
          }

          &:last-child {
            color: #67F1FF;
            &::before {
              display: none;
            }
          }

          &::before {
            content: "|";
            position: absolute;
            right: -3px;
            top: 0;
          }
        }
      }
`;

const AddWallet = styled(Box)`
    text-align: center;
    padding: 30px 0;
    button {
        text-decoration: underline !important;
        padding: 0;
        color: #fff;
        font-weight: 700;
        font-size: 16px;

        .MuiTouchRipple-root {
            display: none;
        }
    }
`;

const ProfileWallet = () => {
    const { address } = useAccount();

    return (
        <>
            <WalletWrapper>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <Box className="right_head">
                        <Typography variant="h3">Wallets</Typography>
                        {/*
                        <Typography variant="body1">
                            Click To Select Primary
                        </Typography>
                        */}
                    </Box>
                </Box>
                <MobViewInner sx={{ display: { xs: "block", sm: "none" } }}>
                    <Image src={assest.subLogo} width={42} height={42} alt='icon' />
                    <Typography variant='h3'>Username’s Wallets</Typography>
                    <List>
                        <ListItem>{getSlicedWallet(address)}</ListItem>
                        <ListItem>KYC Verified</ListItem>
                    </List>
                </MobViewInner>
                <Box className="walletList">
                    <Box className="wallet_btn">
                        <Box className="left_side">
                            <Typography variant="h4">Primary Wallet</Typography>
                            <Typography variant="body1">{getSlicedWallet(address, 16)}</Typography>
                        </Box>
                        <Box className="right_side">
                            <Box>
                                <Typography variant="h4">0 PLF</Typography>
                                <Typography variant="body1">≈ $0</Typography>
                            </Box>
                            <Image
                                src={assest.Substract}
                                alt="img"
                                width={30}
                                height={30}
                            />
                        </Box>
                        {/*<Stack direction="row" className="overlayButtonGroup">
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconEdit}
                                        width={14}
                                        height={14}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Edit</Typography>
                            </TransparentButton>
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconStar}
                                        width={14}
                                        height={14}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Set Primary</Typography>
                            </TransparentButton>
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconTrash}
                                        width={14}
                                        height={19}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Set Primary</Typography>
                            </TransparentButton>
                            </Stack>*/}
                    </Box>
                    {/*<Box className="wallet_btn">
                        <Box className="left_side">
                            <Typography variant="h4">Wallet Name</Typography>
                            <Typography variant="body1">0x89aus...23sd</Typography>
                        </Box>
                        <Box className="right_side">
                            <Box>
                                <Typography variant="h4">11,589.23 PLF</Typography>
                                <Typography variant="body1">≈ $2,568,58</Typography>
                            </Box>
                            <Image
                                src={assest.Star}
                                alt="img"
                                width={30}
                                height={30}
                            />
                        </Box>
                        <Stack direction="row" className="overlayButtonGroup">
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconEdit}
                                        width={14}
                                        height={14}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Edit</Typography>
                            </TransparentButton>
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconStar}
                                        width={14}
                                        height={14}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Set Primary</Typography>
                            </TransparentButton>
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconTrash}
                                        width={14}
                                        height={19}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Set Primary</Typography>
                            </TransparentButton>
                        </Stack>
                    </Box>
                    <Box className="wallet_btn">
                        <Box className="left_side">
                            <Typography variant="h4">Wallet Name</Typography>
                            <Typography variant="body1">0x89aus...23sd</Typography>
                        </Box>
                        <Box className="right_side">
                            <Box>
                                <Typography variant="h4">11,589.23 PLF</Typography>
                                <Typography variant="body1">≈ $2,568,58</Typography>
                            </Box>
                            <Image
                                src={assest.Substract}
                                alt="img"
                                width={30}
                                height={30}
                            />
                        </Box>
                        <Stack direction="row" className="overlayButtonGroup">
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconEdit}
                                        width={14}
                                        height={14}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Edit</Typography>
                            </TransparentButton>
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconStar}
                                        width={14}
                                        height={14}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Set Primary</Typography>
                            </TransparentButton>
                            <TransparentButton
                                type="button"
                                startIcon={
                                    <Image
                                        src={assest.iconTrash}
                                        width={14}
                                        height={19}
                                        alt="icon"
                                    />
                                }
                            >
                                <Typography variant="caption">Set Primary</Typography>
                            </TransparentButton>
                        </Stack>
                    </Box>*/}
                    {/*<AddWallet>
                        <Button>Add Wallet</Button>
                    </AddWallet>
                    */}
                </Box>
            </WalletWrapper>
        </>
    )
}

export default ProfileWallet